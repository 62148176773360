import React from "react";
import { Link } from "react-router-dom";

function HomePage() {
  return (
    <div>
      <h1>Welcome to Our Main Site</h1>
      <p>This is the main page of our website.</p>
      <Link to="/apple-app-site-association">Universal Links Page</Link>
    </div>
  );
}

export default HomePage;
