import React from "react";

function UniversalLinksPage() {
  return (
    <div>
      <h1>Universal Links for Our App</h1>
      <p>This page is used for Apple Universal Links.</p>
    </div>
  );
}

export default UniversalLinksPage;
