import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import UniversalLinksPage from "./pages/UniversalLinksPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/apple-app-site-association"
          element={<UniversalLinksPage />}
        />
      </Routes>
    </Router>
  );
}

export default App;
